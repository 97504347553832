import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  appName: string
}

const AccessRestrictedModal = ({ isOpen, onClose, onCloseComplete, appName }: Props) => {
  return (
    <Modal open={isOpen} onWppModalClose={onClose} onWppModalCloseComplete={onCloseComplete}>
      <WppTypography slot="header" type="xl-heading">
        Access Restricted
      </WppTypography>
      <WppTypography slot="body" type="s-body" data-testid="delete-group-modal-text">
        The {appName} application is restricted by it's owner. Contact support to enable it
      </WppTypography>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s">
          Got it
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showAccessRestrictedModal } = createProjectModal<Props>(
  AccessRestrictedModal,
  'access-restricted-modal',
)
