import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { Project } from 'types/projects/projects'

export const useFileAnalytics = (project?: Project) => {
  const { trackAction } = useTrackAction()
  const { calculateTimeDifferenceInSeconds } = useTimeTracker()
  const {
    osContext: { userDetails },
  } = useOs()

  const getMeFromProjectMembers = useMemo(
    () => project?.members?.find(({ email }) => email === userDetails?.email),
    [project?.members, userDetails?.email],
  )

  const commonAnalyticsData = useMemo(
    () => project && getCommonProjectAnalyticsData(project, getMeFromProjectMembers),
    [project, getMeFromProjectMembers],
  )

  const buildAnalyticsData = useCallback(
    ({ overrides, calculateTime }: { overrides?: Record<string, any>; calculateTime?: boolean }) => ({
      ...commonAnalyticsData,
      ...(calculateTime && { duration_in_seconds: calculateTimeDifferenceInSeconds() }),
      ...overrides,
    }),
    [commonAnalyticsData, calculateTimeDifferenceInSeconds],
  )

  const trackViewProjectFiles = useCallback(
    ({ filtersApplied, searchApplied }: { filtersApplied: boolean; searchApplied: boolean }) => {
      trackAction(
        ACTION_ANALYTICS.ACTION_VIEW_PROJECT_FILES,
        buildAnalyticsData({
          overrides: {
            filters_applied: filtersApplied,
            search_appied: searchApplied,
          },
        }),
      )
    },
    [trackAction, buildAnalyticsData],
  )

  const trackViewProjectFileDetails = useCallback(
    (fileExtension: string) => {
      trackAction(
        ACTION_ANALYTICS.ACTION_VIEW_PROJECT_FILE_DETAILS,
        buildAnalyticsData({
          overrides: {
            file_extension: fileExtension,
          },
        }),
      )
    },
    [trackAction, buildAnalyticsData],
  )

  const trackFileUpload = useCallback(
    ({ fileExtension, fileSize, fileCount }: { fileExtension?: string; fileSize: string; fileCount: number }) => {
      trackAction(
        ACTION_ANALYTICS.ACTION_PROJECT_FILE_UPLOADED,
        buildAnalyticsData({
          overrides: {
            ...(fileExtension && { file_extension: fileExtension }),
            file_size: fileSize,
            file_count: fileCount,
          },
          calculateTime: true,
        }),
      )
    },
    [trackAction, buildAnalyticsData],
  )

  const trackFileUploadCancelled = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_FILE_UPLOAD_CANCELLED, buildAnalyticsData({ calculateTime: true }))
  }, [trackAction, buildAnalyticsData])

  const trackFileDownload = useCallback(
    ({ fileExtension, fileSize }: { fileExtension: string; fileSize: string }) => {
      trackAction(
        ACTION_ANALYTICS.ACTION_PROJECT_FILE_DOWNLOADED,
        buildAnalyticsData({
          overrides: {
            fileExtension,
            file_size: fileSize,
          },
        }),
      )
    },
    [trackAction, buildAnalyticsData],
  )

  return {
    trackViewProjectFiles,
    trackViewProjectFileDetails,
    trackFileUpload,
    trackFileUploadCancelled,
    trackFileDownload,
  }
}
