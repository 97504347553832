import { MayBeNull, NavigationTree } from '@wpp-open/core'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'

export const useAutoSelection = (
  fieldName: string,
  parentId: string,
  data: MayBeNull<NavigationTree>,
  isFetched: boolean,
) => {
  const { setValue } = useFormContext()

  const preselectRef = useRef(false)

  useUpdateEffect(() => {
    if (parentId) {
      preselectRef.current = true
    }
  }, [parentId])

  useEffect(() => {
    // skip effects during request is pending
    if (!isFetched) return

    if (preselectRef.current) {
      preselectRef.current = false

      // parent was changed, if we have only 1 node - let's auto select it
      const mappingList = Object.values(data?.mapping ?? [])

      if (mappingList.length === 1) {
        setValue(fieldName, mappingList[0].azId!)
      }
    }
  }, [data, fieldName, setValue, isFetched])
}
