import { MayBeNull } from '@wpp-open/core'
import * as zod from 'zod'

import { LinearData } from 'pages/project/components/canvas/utils'
import { ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { ActivityItem, ApplicationItem, FluidWorkflow, PhaseItem, PhaseItemType } from 'types/projects/workflow'

export const roleOption = [
  { value: ProjectRole.OWNER, label: 'Owner' },
  { value: ProjectRole.CONTRIBUTOR, label: 'Contributor' },
  { value: ProjectRole.VIEWER, label: 'Viewer' },
]

export const inviteMembersSchema = zod
  .object({
    externalMembers: zod.array(
      zod.object({
        id: zod.string(),
        email: zod.string(),
        firstName: zod
          .string()
          .min(2, 'This field is required and should have at least 2 symbols')
          .regex(/^([a-zA-Z]|\s)*$/, 'Special characters are not allowed'),
        lastName: zod
          .string()
          .min(2, 'This field is required and should have at least 2 symbols')
          .regex(/^([a-zA-Z]|\s)*$/, 'Special characters are not allowed'),
        agency: zod.string().min(1, 'This field is required'),
      }),
    ),
  })
  .passthrough()

export const emailSchema = zod.string().email()

const namesPattern = /^(.+?)[-._](.+)@.+$/
export const emailToNames = (email: string): { firstName: string; lastName: string } => {
  const result = email.match(namesPattern)

  if (!result) {
    return { firstName: '', lastName: '' }
  }

  const [, firstName, lastName] = result
  return { firstName, lastName }
}

export const getNotAllowedApps = (
  processType: ProcessType,
  fluidData: MayBeNull<FluidWorkflow>,
  linearData: LinearData,
) => {
  const apps: ApplicationItem[] = []
  if (processType === ProcessType.FLUID) {
    fluidData?.items.forEach(item => {
      if (item.hasOwnProperty('items')) {
        ;(item as ActivityItem).items.forEach(subItem => {
          apps.push(subItem.application)
        })
      } else {
        apps.push(item as ApplicationItem)
      }
    })
  } else {
    Object.values(linearData.items).forEach(item => {
      item.hasOwnProperty('itemType') &&
        (item as PhaseItem<ApplicationItem>).itemType === PhaseItemType.Application &&
        apps.push((item as PhaseItem<ApplicationItem>).item)
    })
  }

  return apps.filter(app => !app.externalAppVersions[0].isPublic)
}
