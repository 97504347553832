import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import styles from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeApps.module.scss'
import { SelectedApp } from 'pages/project/components/canvas/components/appPikerModal/types'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  apps: SelectedApp[]
  onAddApps: () => void
}

const AddRestrictedAppsModal = ({ isOpen, onClose, onCloseComplete, apps, onAddApps }: Props) => {
  const { t } = useTranslation()

  return (
    <SideModal open={isOpen} onWppSideModalClose={onClose} onWppSideModalCloseComplete={onCloseComplete} size="m">
      <WppTypography slot="header" type="2xl-heading">
        Add restricted applications?
      </WppTypography>
      <div slot="body">
        <WppTypography type="s-body">
          One or more apps are restricted for external users by its owner. External project members might not be able to
          launch it.
        </WppTypography>
        <Flex direction="column" gap={12}>
          {apps.map((app, idx) => {
            return (
              <Flex key={idx} gap={8}>
                <ApplicationLogo logo={app?.logo} size={40} />
                <Truncate lines={1} type="m-strong" className={styles.colGrey1000} data-testid="product-name">
                  {app.name}
                </Truncate>
              </Flex>
            )
          })}
        </Flex>
      </div>
      <Flex justify="end" slot="actions">
        <Flex gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('modals.create_project.btn_cancel')}
          </WppButton>

          <WppButton
            variant="primary"
            size="m"
            onClick={() => {
              onAddApps()
              onClose()
            }}
          >
            Add
          </WppButton>
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showAddRestrictedAppsModal } = createProjectModal<Props>(
  AddRestrictedAppsModal,
  'add-restricted-apps-modal',
)
