import { useOs } from '@wpp-open/react'
import { isEqual } from 'lodash'
import { useCallback, useMemo } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { getCommonProjectAnalyticsData, useTrackAction } from 'hooks/useAnalytics'
import { ContextHierarchy, ProcessType, Project, ProjectStatus } from 'types/projects/projects'

interface UseProjectAnalyticsProps {
  project: Project
  eventSource?: string | undefined
  calculateTimeDifferenceInSeconds?: () => number
}

const actionMap = {
  [ProjectStatus.TO_DO]: ACTION_ANALYTICS.ACTION_PROJECT_STATUS_CHANGED,
  [ProjectStatus.IN_PROGRESS]: ACTION_ANALYTICS.ACTION_PROJECT_STATUS_CHANGED,
  [ProjectStatus.COMPLETED]: ACTION_ANALYTICS.ACTION_PROJECT_STATUS_COMPLETED,
  [ProjectStatus.ARCHIVED]: ACTION_ANALYTICS.ACTION_PROJECT_STATUS_ARCHIVED,
}

export const useProjectAnalytics = ({
  project,
  eventSource,
  calculateTimeDifferenceInSeconds,
}: UseProjectAnalyticsProps) => {
  const { trackAction } = useTrackAction()
  const {
    osContext: { userDetails },
  } = useOs()

  const getMeFromProjectMembers = useMemo(
    () => project.members?.find(({ email }) => email === userDetails?.email),
    [project.members, userDetails?.email],
  )

  const buildAnalyticsData = useCallback(
    (overrides?: Partial<Project>) => ({
      ...getCommonProjectAnalyticsData(project),
      user_agency: getMeFromProjectMembers?.agency,
      user_role: getMeFromProjectMembers?.role,
      process_type: overrides?.processType || project.processType,
      ...(calculateTimeDifferenceInSeconds && { duration_in_seconds: calculateTimeDifferenceInSeconds() }),
      ...(eventSource && { event_source: eventSource }),
      ...overrides,
    }),
    [project, getMeFromProjectMembers, calculateTimeDifferenceInSeconds, eventSource],
  )

  const trackIfChanged = useCallback(
    (action: ACTION_ANALYTICS, condition: boolean, overrides?: Partial<Project>) => {
      if (condition) {
        trackAction(action, buildAnalyticsData(overrides))
      }
    },
    [trackAction, buildAnalyticsData],
  )

  const trackHierarchyChange = useCallback(
    (newContextHierarchy: ContextHierarchy[]) => {
      const hierarchyChanged = !isEqual(project.contextHierarchy, newContextHierarchy)
      trackIfChanged(ACTION_ANALYTICS.ACTION_PROJECT_HIERARCHY_CHANGED, hierarchyChanged)
    },
    [project.contextHierarchy, trackIfChanged],
  )
  const trackProjectStatusChange = useCallback(
    (status: ProjectStatus) => {
      const action = actionMap[status]

      if (action) {
        trackAction(action, buildAnalyticsData({ status }))
      }
    },
    [buildAnalyticsData, trackAction],
  )

  const trackProjectDelete = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_DELETED, buildAnalyticsData())
  }, [buildAnalyticsData, trackAction])

  const trackProcessTypeChange = useCallback(
    (newProcessType: ProcessType) => {
      const processTypeChanged = project.processType !== newProcessType

      trackIfChanged(
        ACTION_ANALYTICS.ACTION_PROJECT_PROCESS_TYPE_CHANGED,
        processTypeChanged,
        { processType: newProcessType }, // Pass the new process type as an override
      )
    },
    [project.processType, trackIfChanged],
  )

  const trackWrikeAction = useCallback(
    (disconnectWrike: boolean) => {
      const wrikeConnectionChanged = !isEqual(project.wrike?.isConnected, !disconnectWrike)

      if (!wrikeConnectionChanged) return

      const action = disconnectWrike
        ? ACTION_ANALYTICS.ACTION_PROJECT_WRIKE_DISCONNECTED
        : ACTION_ANALYTICS.ACTION_PROJECT_WRIKE_CONNECTED
      trackAction(action, buildAnalyticsData())
    },
    [project, trackAction, buildAnalyticsData],
  )

  const trackProjectEdit = useCallback(
    (overrides?: Partial<Project>) => {
      trackAction(ACTION_ANALYTICS.ACTION_PROJECT_EDIT, buildAnalyticsData(overrides))
    },
    [trackAction, buildAnalyticsData],
  )

  const trackProjectEditCancel = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_EDIT_CANCELLED, buildAnalyticsData())
  }, [trackAction, buildAnalyticsData])

  const trackProjectPresentationModeSlideEdit = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_PROJECT_PRESENTATION_MODE_SLIDE_EDIT, buildAnalyticsData())
  }, [trackAction, buildAnalyticsData])

  const trackProjectPresentationModeSlideView = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_VIEW_PROJECT_PRESENTATION_MODE_SLIDE_VIEW, buildAnalyticsData())
  }, [trackAction, buildAnalyticsData])

  const trackProjectPresentationMode = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_VIEW_PROJECT_PRESENTATION_MODE, buildAnalyticsData())
  }, [trackAction, buildAnalyticsData])

  return {
    trackHierarchyChange,
    trackProcessTypeChange,
    trackWrikeAction,
    trackProjectEdit,
    trackProjectEditCancel,
    trackProjectStatusChange,
    trackProjectDelete,
    trackProjectPresentationModeSlideEdit,
    trackProjectPresentationModeSlideView,
    trackProjectPresentationMode,
  }
}
