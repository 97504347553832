import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { ACTION_ANALYTICS } from 'constants/analytics'
import { useTrackAction } from 'hooks/useAnalytics'
import { useTimeTracker } from 'hooks/useTimeTracker'
import { Template } from 'types/projects/template'

interface TemplateAnalyticsData {
  processType?: string
  hasTags?: boolean
  numberOfPhases?: number
  numberOfApps?: number
}

export const useTemplateAnalytics = (template?: Template) => {
  const { trackAction } = useTrackAction()

  const { calculateTimeDifferenceInSeconds } = useTimeTracker()
  const {
    osContext: { userDetails, tenant },
  } = useOs()

  const buildAnalyticsData = useCallback(
    (trackDuration?: boolean) => ({
      user_agency: userDetails.agency,
      workspace_name: tenant.name,
      workspace_type: tenant.tenantType,
      ...(template && { template_id: template.id }),
      ...(trackDuration && { duration_in_seconds: calculateTimeDifferenceInSeconds() }),
    }),
    [userDetails.agency, tenant.name, tenant.tenantType, template, calculateTimeDifferenceInSeconds],
  )

  const trackTemplateEdit = useCallback(() => {
    const analyticsData = buildAnalyticsData(true)

    trackAction(ACTION_ANALYTICS.ACTION_TEMPLATE_EDIT, analyticsData)
  }, [buildAnalyticsData, trackAction])

  const trackTemplateCreateCancel = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_TEMPLATE_CREATE_CANCELLED, buildAnalyticsData(true))
  }, [trackAction, buildAnalyticsData])

  const trackTemplateEditCancel = useCallback(() => {
    trackAction(ACTION_ANALYTICS.ACTION_TEMPLATE_EDIT_CANCELLED, buildAnalyticsData(true))
  }, [trackAction, buildAnalyticsData])

  const trackTemplateDelete = useCallback(
    (id: string) => {
      trackAction(ACTION_ANALYTICS.ACTION_TEMPLATE_DELETE, { ...buildAnalyticsData(), template_id: id })
    },
    [trackAction, buildAnalyticsData],
  )

  const trackTemplateCreate = useCallback(
    ({ numberOfApps, numberOfPhases, processType, hasTags }: TemplateAnalyticsData) => {
      trackAction(ACTION_ANALYTICS.ACTION_TEMPLATE_CREATE, {
        ...buildAnalyticsData(true),
        process_type: processType,
        has_tags: hasTags,
        ...(numberOfPhases && { number_of_phases: numberOfPhases }),
        ...(numberOfApps && { number_of_apps: numberOfApps }),
      })
    },
    [trackAction, buildAnalyticsData],
  )

  const trackViewWorkflowTemplate = useCallback(
    ({ numberOfApps, numberOfPhases, processType, hasTags }: TemplateAnalyticsData) => {
      trackAction(ACTION_ANALYTICS.ACTION_VIEW_WORKFLOW_TEMPLATE, {
        ...buildAnalyticsData(),
        process_type: processType,
        has_tags: hasTags,
        ...(numberOfPhases && { number_of_phases: numberOfPhases }),
        ...(numberOfApps && { number_of_apps: numberOfApps }),
      })
    },
    [trackAction, buildAnalyticsData],
  )

  return {
    trackTemplateEdit,
    trackTemplateCreateCancel,
    trackTemplateDelete,
    trackTemplateCreate,
    trackViewWorkflowTemplate,
    trackTemplateEditCancel,
  }
}
